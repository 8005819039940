export const listZones = /* GraphQL */ `
    query ListZones(
        $id: ID
        $filter: ModelZoneFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listZones(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                counties
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;

export const updateZone = /* GraphQL */ `
    mutation UpdateZone(
        $input: UpdateZoneInput!
        $condition: ModelZoneConditionInput
    ) {
        updateZone(input: $input, condition: $condition) {
            id
            name
            counties
            state {
                enabled
            }
        }
    }
`;

export const onCreateZone = /* GraphQL */ `
    subscription OnCreateZone {
        onCreateZone {
            id
            name
            counties
            state {
                enabled
            }
        }
    }
`;

export const onUpdateZone = /* GraphQL */ `
    subscription OnUpdateZone {
        onUpdateZone {
            id
            name
            counties
            state {
                enabled
            }
        }
    }
`;

export const onDeleteZone = /* GraphQL */ `
    subscription OnDeleteZone {
        onDeleteZone {
            id
            name
            counties
            state {
                enabled
            }
        }
    }
`;
