<template>
  <page-layout ref="layout">
    <template #actions="{ }">
      <can do="create" on="management-districts">
        <b-button v-b-tooltip="'Create'" v-b-modal="'district-modal'" variant="transparent" size="sm">
          <font-awesome-icon icon="fa-solid fa-plus" />
        </b-button>
        <district-modal id="district-modal" @created="addDistrict"></district-modal>
      </can>
    </template>

    <template #dropdown-options="{ }">
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
      <table-layout ref="table-layout"
                    :items="table.items"
                    :loading="table.loading"
                    :fields="table.fields"
                    :filters="table.filters" :filters-options="{ visible: true, collapsed: true }"
                    :sorting="table.sorting"
                    :subscriptions="table.subscriptions"
                    :func-delete="deleteDistrict"
                    :func-refresh="refresh"
                    :export-exclude-fields="[
                        'id',
                        'slug',
                        'avatar.size',
                        'avatar.src',
                        'avatar.name',
                        'zone.id',
                        'districtZoneId',
                        'state.enabled',
                    ]"
                    @mounted="table = $event"
                    @updated="table = $event">

        <template #overlay>
          <overlay-loading :items="[
            { state: options.zones.loading, desc: 'Loading Zones', loaded: options.zones.items.length },
            { state: table.loading, desc: 'Loading Districts', loaded: table.loaded },
            { state: state.loading, desc: 'Rendering Template'},
          ]" />
        </template>

        <!-- Filters -->
        <template #filters>
          <b-row>
            <b-col>
              <b-form-group label="Zone" label-for="zone-input">
                <v-select id="zone-input"
                          v-model="table.filters.zone.value"
                          :options="zones" label="name"
                          :reduce="zone => zone.id"
                          :loading="options.zones.loading"
                          :select-on-tab="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100"/>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Enabled" label-for="enabled-input">
                <v-select id="enabled-input"
                          v-model="table.filters.enabled.value"
                          :options="['true', 'false']"
                          :searchable="false"
                          :select-on-tab="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100"/>
              </b-form-group>
            </b-col>
          </b-row>
        </template>

        <!-- Column: Name -->
        <template #cell(name.legal)="{data}">
          <b-media vertical-align="center" no-body>
            <b-media-aside>
              <b-avatar v-if="hasCustomAvatar(data.item.avatar)" v-b-modal.avatar-modal variant="primary" size="2.5em" badge-variant="white">
                <b-img fluid fluid-grow :src="data.item.avatar.src" :alt="data.item.avatar.name"></b-img>
              </b-avatar>
              <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="2.5em" badge-variant="white">
                <font-awesome-icon :icon="icon"></font-awesome-icon>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="align-self-center">
              <b-link :to="{ name: 'management-district', params: { id: data.item.id, slug: data.item.slug } }" class="font-weight-bold d-block text-nowrap">
                {{ data.item.name.legal }}
              </b-link>
            </b-media-body>
          </b-media>
        </template>

        <template #cell(zone.name)="{data}">
          <span v-if="data.item.zone">{{ data.item.zone.name }}</span>
          <span v-else class="text-danger">No Zone</span>
        </template>

        <!-- Column: State - Enabled -->
        <template #cell(state.enabled)="{data}">
          <b-badge pill :variant="`light-${data.item.state.enabled ? 'success' : 'danger'}`" class="text-capitalize">
            {{ data.item.state.enabled }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(row-options)="{data}">
          <b-dropdown-item :to="{ name: 'management-district', params: { id: data.item.id, slug: data.item.slug } }"
                           class="table-row-option-view">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">View</span>
          </b-dropdown-item>

          <template v-if="$can('update', 'management-districts') || $can('delete', 'management-districts')">
            <b-dropdown-divider />
            <can do="update" on="management-districts">
              <b-dropdown-item  class="table-row-option-enable"
                                @click="updateDistrict(data.item)">
                <font-awesome-icon :icon="['fas', data.item.state.enabled ? 'toggle-off' : 'toggle-on']"></font-awesome-icon>
                <span class="align-middle ml-50">{{ data.item.state.enabled ? 'Disable' : 'Enable' }}</span>
              </b-dropdown-item>
            </can>
            <can do="delete" on="management-districts">
              <b-dropdown-item class="table-row-option-delete"
                               @click="$refs.layout.confirmDelete(data.item, deleteDistrict, cascadeConfirmDeleteOptions)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </can>
          </template>

        </template>
      </table-layout>
    </template>

    <template #debug>
      <debug :collapsed="true">{{ table }}</debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import TableLayout from '@/components/TableLayout.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import DistrictModal from '@/views/management/districts/DistrictModal.vue'
import avatar from '@/mixins/avatar.mixin'
import notify from '@/mixins/notify.mixin';
import print from '@/mixins/print.mixin';
import vSelect from 'vue-select'
import { API, graphqlOperation } from 'aws-amplify';
import {
  listDistricts, listUserSchools, listZones,
  updateDistrict,
  deleteDistrict, deleteSchool, deleteUserSchools,
  onCreateDistrict, onUpdateDistrict, onDeleteDistrict
} from './queries/districts';
import { cascadeDeleteDistrict, cascadeConfirmDeleteOptions} from '@/graphql/cascade/district';

export default {
  name: 'Districts',
  components: {
    OverlayLoading,
    PageLayout,
    TableLayout,
    DistrictModal,
    vSelect
  },
  mixins: [ avatar, notify, print ],
  data() {
    return {
      table: {
        items: [],
        loading: true,
        fields: [
          {
            key: 'name.legal',
            label: 'Name',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'name.popular',
            label: 'Other Name',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'beds',
            label: 'BEDS',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'schools.items.length',
            label: 'Schools',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle',
            isQueryField: true
          },
          {
            key: 'zone.name',
            subKeys: ['zone.id', 'districtZoneId'],
            label: 'Zone',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle',
            isQueryField: true
          },
          {
            key: 'state.enabled',
            label: 'Enabled',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'row-options',
            label: '',
            sortable: false,
            visible: true,
            tdClass: ['align-middle', 'table-row-options']
          },
        ],
        filters: {
          zone: { key: 'districtZoneId', value: null },
          enabled: { key: 'state.enabled', value: null },
        },
        sorting: {
          by: 'name.legal',
          desc: false
        },
        subscriptions: {
          onCreate: null,
          onUpdate: null,
          onDelete: null
        }
      },
      options: {
        zones: {
          items: [],
          loading: false,
          loaded: false,
        },
      },
      icon: 'fa-solid fa-building-columns',
      debug: { },
      cascadeConfirmDeleteOptions
    }
  },
  computed: {
    filterEnabledOptions() {
      return [{ label: 'true', value: true }, { label: 'false', value: 'false' }]
    },
    zones() {
      if(!this.options.zones.loaded) return []
      return this.options.zones.items
    }
  },
  async created() {
    await this.listZones();
    await this.listDistricts();
    this.onCreateDistrict();
    this.onUpdateDistrict();
    this.onDeleteDistrict();
  },
  mounted() {
    this.$refs.layout.state.loading = false
  },
  methods: {
    /** Table Data **/
    async listDistricts(nextToken, pagedDistricts) {
      this.table.loading = true
      const districts = pagedDistricts || []

      const input = {
        limit: 500,
        nextToken: nextToken,
        includeZone: this.table.fields.find(field => field.key === 'zone.name')?.visible || false,
        includeSchools: this.table.fields.find(field => field.key === 'schools.items.length')?.visible || false
      }

      const response = await API.graphql(graphqlOperation(listDistricts, input));
      districts.push(...response.data.listDistricts.items);

      this.table.loaded = districts.length


      if(response.data.listDistricts.nextToken) {
        await this.listDistricts(response.data.listDistricts.nextToken, districts)
      }
      else {
        this.table.items = districts
        this.table.loading = false
      }
    },
    async addDistrict(district) {
      this.table.loading = true
      this.table.items.push(district)
      this.table.loading = false
    },
    async updateDistrict(district) {
      try {
        district.state.enabled = !district.state.enabled
        await API.graphql(graphqlOperation(updateDistrict, { input: { id: district.id, state: district.state } }));
        this.notify({ title: 'Success', text: 'District was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch (err) {
        console.error(err)
        this.notify({ title: 'Error', text: 'District failed to update', icon: this.icon, variant: 'danger' });
      }
    },
    async deleteDistrict(district, swalCallback) {
      try {
        this.table.loading = true
        await this.cascadeDeleteDistrict(district.id, swalCallback)
        this.table.items = this.table.items.filter(item => item.id !== district.id);
        this.notify({ title: 'Success', text: 'District was successfully deleted', icon: this.icon, variant: 'success' });
      }
      catch (error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'District failed to delete', icon: this.icon, variant: 'danger' });
        throw error //for Swal
      }
      finally {
        this.table.loading = false
      }
    },
    cascadeDeleteDistrict,

    /** Subscriptions **/
    onCreateDistrict() {
      this.table.subscriptions.onCreate = API.graphql(graphqlOperation(onCreateDistrict)).subscribe((sourceData) => {
        this.syncNotification()
        const district = sourceData.value.data.onCreateDistrict
        if (district && !this.table.items.map(item => item.id).includes(district.id)) {
          this.table.loading = true
          this.table.items = [district, ...this.table.items];
          this.table.loading = false
        }
      });
    },
    onUpdateDistrict() {
      this.table.subscriptions.onUpdate = API.graphql(graphqlOperation(onUpdateDistrict)).subscribe((sourceData) => {
        this.syncNotification()
        const district = sourceData.value.data.onUpdateDistrict
        if (district && this.table.items.map(item => item.id).includes(district.id)) {
          this.table.loading = true
          const index = this.table.items.findIndex(item => item.id === district.id)
          if(index > -1) {
            this.table.items.splice(index, 1, district)
          }
          this.table.loading = false
        }
      });
    },
    onDeleteDistrict() {
      this.table.subscriptions.onDelete = API.graphql(graphqlOperation(onDeleteDistrict)).subscribe((sourceData) => {
        this.syncNotification()
        const district = sourceData.value.data.onDeleteDistrict
        if(district && this.table.items.map(item => item.id).includes(district.id)) {
          this.table.loading = true
          this.table.items = this.table.items.filter(item => item.id !== district.id);
          this.table.loading = false
        }
      });
    },

    /** Options **/
    async listZones() {
      this.options.zones.loading = true
      const response = await API.graphql(graphqlOperation(listZones));
      this.options.zones.items = response.data.listZones.items?.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));
      this.options.zones.loading = false
      this.options.zones.loaded = true
    },

    /** Util **/
    async refresh() {
      this.table.loaded = 0
      await this.listDistricts()
    },
  }
}
</script>

<style lang="scss">

</style>
