/* eslint-disable no-await-in-loop */
import {API, graphqlOperation} from 'aws-amplify';
import {cascadeDeleteSchool} from '@/graphql/cascade/school';
import {batchProcessWithRetry, SwalData} from '@/graphql/cascade/batch';

export const cascadeConfirmDeleteOptions = {
    confirm: {
        text: `
          <p class='mb-0'>Deleting this District may have unwanted side effects.</p>
          <p class='font-small-3 text-danger'>
            Schools associated with this district will be deleted.<br/>
            Teachers & Students who are currently assigned to these schools will have their association removed.<br/>
            This may effect applications, selections, acceptance, as well as reports.
          </p>
          <strong>Be very careful when performing this action.</strong>
        `,
        shouldParse: true
    },
    confirmed: { text: 'District was successfully deleted.' }
}

export async function cascadeDeleteDistrict(districtId, swalCallback) {
    try {
        /** Delete School records associated to this District
         * Note that this is a recursive call to the cascadeDeleteSchool function
         * which will delete all User-School records associated to the Schools (User records will not be deleted)
         * and update all Student records associated to the Schools (removing the connection).
         **/
        const schools = await listSchools(districtId, swalCallback)
        await batchProcessWithRetry(schools, async (school) => {
            await cascadeDeleteSchool(school.id, swalCallback)
            await new Promise(resolve => setTimeout(resolve, 1000));
        }, { title: 'Deleting Schools', callback: swalCallback })

        await new Promise(resolve => setTimeout(resolve, 1000));

        /** Delete District **/
        await deleteDistrict(districtId, swalCallback)
    }
    catch(e) {
        console.error(e)
    }
}

/** Helper Functions **/
async function deleteDistrict(districtId, swalCallback) {
    const title = 'Deleting District'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
    await API.graphql(graphqlOperation(deleteDistrictMutation, { input: { id: districtId }} ));
    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
}

async function listSchools(districtId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { districtSchoolsId: { eq: districtId } } }
            const response = await API.graphql(graphqlOperation(listSchoolsQuery, input));
            items.push(...response.data.listSchools.items)
            nextToken = response.data.listSchools.nextToken;
            swalCallback(new SwalData('Loading Schools', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    await new Promise(resolve => setTimeout(resolve, 1000));
    return items
}

/** Queries & Mutations **/
const listSchoolsQuery = /* GraphQL */ `
    query ListSchools(
        $id: ID
        $filter: ModelSchoolFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSchools(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
            }
            nextToken
        }
    }
`;

const deleteDistrictMutation = /* GraphQL */ `
    mutation DeleteDistrict(
        $input: DeleteDistrictInput!
        $condition: ModelDistrictConditionInput
    ) {
        deleteDistrict(input: $input, condition: $condition) {
            id
        }
    }
`;
