<template>
  <b-modal :id="id" centered size="xl" title="New District" no-close-on-backdrop footer-class="d-block">
    <!-- Content -->
    <template #default>
      <b-container>

        <b-alert show variant="primary" class="my-2">
          <b-icon icon="info-circle" class="mr-2"/>Additional information can be managed after the creation of a new district.
        </b-alert>

        <validation-observer ref="observer" v-slot="{ handleSubmit }" tag="div">
          <form ref="form" @submit.stop.prevent="handleSubmit(create)">
            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="name-legal" name="Legal Name" rules="required">
                  <b-form-group label="Legal Name" label-for="name-legal" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="name-legal" v-model="district.name.legal" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider v-slot="validationContext" vid="name-popular" name="Popular Name" rules="required">
                  <b-form-group label="Popular Name" label-for="name-popular" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="name-popular" v-model="district.name.popular" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="zone" name="Zone" rules="required">
                  <b-form-group label="Zone" label-for="zone-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                    <v-select id="zone-input"
                              v-model="district.zone"
                              label="name"
                              :loading="options.zones.loading"
                              :options="options.zones.items"
                              :selectable="option => option.state.enabled === true"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="w-100"
                              @input="district.address.county = null">
                      <template #option="{ name, state }">
                        <div class="d-flex justify-content-between">
                          <span>{{ name }}</span> <span v-if="!state.enabled" title="Disabled"><b-icon-lock-fill /></span>
                        </div>
                      </template>
                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="line1" name="Line 1" rules="required">
                  <b-form-group label="Line 1" label-for="line1-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="line1-input" v-model="district.address.line1" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider v-slot="validationContext" vid="line2" name="Line 2" rules="">
                  <b-form-group label="Line 2" label-for="line2-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="line2-input" v-model="district.address.line2" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="city" name="City" :rules="rules.city">
                  <b-form-group label="City" label-for="city-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="city-input" v-model="district.address.city" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider v-slot="validationContext" vid="county" name="County" rules="required">
                  <b-form-group label="County" label-for="county-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                    <v-select id="county-input"
                              v-model="district.address.county"
                              :options="counties"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="w-100"/>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="auto">
                <validation-provider v-slot="validationContext" vid="state" name="State" :rules="rules.state">
                  <b-form-group label="State" label-for="state-input" :invalid-feedback="validationContext.errors[0]">
                    <state-input id="state-input" v-model="district.address.state" :validation-state="getValidationState(validationContext)" />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="auto">
                <validation-provider v-slot="validationContext" vid="zip-code" name="Zip Code" :rules="rules.zip">
                  <b-form-group label="Zip Code" label-for="zip-code-input" :invalid-feedback="validationContext.errors[0]">
                    <zip-input id="zip-code-input" v-model="district.address.zip" :state="getValidationState(validationContext)" />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

          </form>
        </validation-observer>
      </b-container>
    </template>

    <!-- Footer -->
    <template #modal-footer>
      <b-row>
        <b-col align-self="center">
          <b-checkbox v-model="state.redirect" :checked="true" :unchecked-value="false">
            <small>Redirect to page on submit</small>
          </b-checkbox>
        </b-col>
        <b-col align-self="center" class="text-right">
          <b-button type="reset" class="mr-1" @click="clear()">Clear</b-button>
          <b-button type="submit" variant="primary" @click="$refs.observer.handleSubmit(create)">Submit</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
  import vSelect from 'vue-select'
  import { uuid } from 'vue-uuid';
  import notify from '@/mixins/notify.mixin'
  import slugify from 'slugify';
  import {API, graphqlOperation} from 'aws-amplify';
  import {createDistrict, listZones} from './queries/district-modal';
  import Avatar from '@/models/avatar';
  import StateInput from '@/components/StateInput.vue';
  import { rules } from '@/data/validation-rules';
  import ZipInput from '@/components/ZipInput.vue';
  import {onUpdateZone} from '@/graphql/queries/zones';

  export default {
    name: 'DistrictModal',
    components: {
      ZipInput,
      StateInput,
      vSelect
    },
    mixins: [ notify ],
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        district: {
          name: {
            legal: null,
            popular: null,
          },
          beds: null,
          zone: null,
          address: {
            line1: null,
            line2: null,
            city: null,
            county: null,
            state: null,
            zip: null
          },
          staff: []
        },
        options: {
          zones: {
            items: [],
            loading: true,
            subscription: null
          }
        },
        rules: {
          city: rules.address.city,
          state: rules.address.state,
          zip: rules.address.zip
        },
        state: {
          redirect: false
        },
      }
    },
    computed: {
      counties() {
        return this.district?.zone ? this.options.zones.items.find(zone => zone.id === this.district.zone.id)?.counties?.sort((a, b) => a.localeCompare(b)) : []
      }
    },
    async created() {
      await this.getZones()
      await this.onUpdateZone()
    },
    beforeDestroy() {
      this.options.zones.subscription.unsubscribe()
    },
    methods: {
      async create() {
        try {
          const id = uuid.v4();
          const slug = slugify(this.district.name.legal, { lower: true });
          const input = {
            id: id,
            slug: slug,
            beds: this.district.beds,
            name: this.district.name,
            districtZoneId: this.district.zone.id,
            avatar: new Avatar(),
            address: this.district.address,
            staff: this.district.staff,
            state: {
              enabled: true
            }
          }

          const response = await API.graphql(graphqlOperation(createDistrict, { input: input }));
          const district = response.data.createDistrict

          if(this.state.redirect) {
            await this.$router.push({name: 'management-district', params: { id: id, slug: slug }})
          }
          else {
            this.$emit('created', district)
            this.$root.$emit('bv::toggle::modal', this.id)
            await this.notify({ title: 'Success', text: 'District was successfully created', icon: 'fas fa-server', variant: 'success' });
          }
        }
        catch (error) {
          console.error(error)
          this.notify({ title: 'Error', text: 'District failed to create', icon: 'fas fa-server', variant: 'danger'});
        }
      },
      async getZones() {
        const response = await API.graphql(graphqlOperation(listZones));
        this.options.zones.items = response.data.listZones.items.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));
        this.options.zones.loading = false
      },
      async onUpdateZone() {
        this.options.zones.subscription = API.graphql(graphqlOperation(onUpdateZone)).subscribe((sourceData) => {
          this.syncNotification()
          const zone = sourceData.value.data.onUpdateZone
          if (zone && this.options.zones.items.map(item => item.id).includes(zone.id)) {
            this.options.zones.loading = true
            const index = this.options.zones.items.findIndex(item => item.id === zone.id)
            if(index > -1) {
              this.options.zones.items.splice(index, 1, zone)
            }
            this.options.zones.loading = false
          }
        });
      },
      clear() {
        this.district = {
          name: null,
          form: null,
          address: {
            line1: null,
            line2: null,
            city: null,
            county: null,
            state: null,
            zip: null
          }
        }
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
    }
  }
</script>

<style scoped>

</style>
